<template>
  <div id="page">
    <router-view />
  </div>
</template>

<style lang="less">
@import "../src/styles/main.less";
</style>

<script>
// @ is an alias to /src

export default {
  name: 'App',
}
</script>
