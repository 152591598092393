<template>
  <footer class="foot">
    <div class="foot__top">
      <div class="container">
        <div class="container__elem container__elem--3">
          <a href="/" class="foot__logo">
            <img src="../assets/img/design/logo.svg" alt="">
          </a>
          <!-- /.foot__logo -->
          <div class="foot__podtext">Доставим ваш груз<br> до двери прямо из Китая</div>
          <!-- /.foot__podtext -->
          <div class="copy">
            © 2023 — Delivery Asia
          </div>
          <!-- /.copy -->
          <a href="#" class="foot__pol">Политика<br> конфиденциальности</a>
          <!-- /.foot__pol -->
        </div>
        <div class="container__elem container__elem--3">
          <div class="foot__title">Услуги</div>
          <!-- /.foot__title -->
          <nav class="bottomMenu">
            <ul>
              <li><a href="#">Аренда склада</a></li>
              <li><a href="#">Поиск поставщика</a></li>
              <li><a href="#">Страхование груза</a></li>
              <li><a href="#">Инспекция фабрики</a></li>
              <li><a href="#">Выкуп с Алибаба</a></li>
              <li><a href="#">Выкуп с Таобао</a></li>
              <li><a href="#">Упаковка груза</a></li>
              <li><a href="#">Все услуги
                  <span>
                    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10.5468 3.44703L-2.00873e-07 3.44703L-1.50655e-07 4.59589L10.5465 4.59589L8.0421 7.10027L8.98431 8.04248L12.7532 4.27363L12.7477 4.26817L13 4.01585L8.98425 9.97431e-05L8.04204 0.942311L10.5468 3.44703Z"
                        fill="#F2243A" />
                    </svg>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
          <!-- /.bottomMenu -->
        </div>
        <div class="container__elem container__elem--3">
          <div class="foot__title">Информация</div>
          <!-- /.foot__title -->
          <nav class="bottomMenu">
            <ul>
              <li><a href="#">Отзывы</a></li>
              <li><a href="#">Требования к грузу</a></li>
              <li><a href="#">Договор-оферта</a></li>
              <li><a href="#">Как заключить договор</a></li>
              <li><a href="#">Способы оплаты услуг</a></li>
              <li><a href="#">Документы</a></li>
              <li><a href="#">Статьи</a>
              </li>
            </ul>
          </nav>
          <!-- /.bottomMenu -->
        </div>
        <div class="container__elem container__elem--3">
          <div class="foot__title foot__title--xs">Контакты</div>
          <!-- /.foot__title -->
          <div class="foot__info">
            <button type="button">
              <span>Москва</span>
              <em>
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.56751 5.69472L4.87279 6L9.73255 1.14024L8.59231 0L4.86623 3.72607L1.14024 8.0316e-05L0 1.14032L4.56096 5.70128L4.56751 5.69472Z"
                    fill="#F2243A" />
                </svg>
              </em>
            </button>
            <p>Проспект 1-й Муринский, д. 25,<br> 2 этаж </p>
          </div>
          <!-- /.foot__info -->
          <div class="foot__info1">
            <a href="tel:+79991484319">+7 (999) 148-43-19</a>
            <button type="button">
              <span>Заказать звонок</span>
            </button>
          </div>
          <!-- /.foot__info1 -->
          <div class="social">
            <a href="#">
              <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.0027 11.1423H15.9498C15.1731 11.1423 14.9391 10.5132 13.5468 9.12002C12.33 7.94732 11.8161 7.80152 11.5083 7.80152C11.0826 7.80152 10.9665 7.91852 10.9665 8.50442V10.3512C10.9665 10.8507 10.8045 11.1432 9.4995 11.1432C8.23346 11.0582 7.00574 10.6736 5.91747 10.0211C4.8292 9.36857 3.91151 8.46686 3.24 7.39022C1.64555 5.40574 0.536137 3.07659 0 0.588021C0 0.280221 0.117 0.00122113 0.7038 0.00122113H2.7558C3.2832 0.00122113 3.4731 0.236121 3.6801 0.778821C4.6764 3.71102 6.3765 6.26162 7.0668 6.26162C7.3314 6.26162 7.4466 6.14462 7.4466 5.48492V2.46452C7.3593 1.08662 6.6276 0.970521 6.6276 0.471921C6.63686 0.340378 6.69703 0.217625 6.79534 0.129735C6.89365 0.0418451 7.02235 -0.004257 7.1541 0.00122113H10.3797C10.8207 0.00122113 10.9665 0.220821 10.9665 0.748221V4.82522C10.9665 5.26532 11.1555 5.41112 11.2887 5.41112C11.5533 5.41112 11.7567 5.26532 12.2418 4.78112C13.2816 3.51301 14.1311 2.10017 14.7636 0.587121C14.8283 0.405459 14.9507 0.249958 15.1121 0.144337C15.2734 0.0387149 15.4649 -0.0112297 15.6573 0.00212106H17.7102C18.3258 0.00212106 18.4563 0.309921 18.3258 0.749121C17.5791 2.4217 16.6553 4.00937 15.57 5.48492C15.3486 5.82242 15.2604 5.99792 15.57 6.39392C15.7734 6.70172 16.4934 7.30292 16.9767 7.87442C17.6805 8.57642 18.2646 9.38822 18.7065 10.2774C18.8829 10.8498 18.5886 11.1423 18.0027 11.1423Z"
                  fill="#fff" />
              </svg>
            </a>
            <a href="#">
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.3336 0.150026L0.512706 5.53557C-0.0434705 5.78637 -0.231584 6.28861 0.378278 6.56118L3.92392 7.69979L12.4969 2.34598C12.9649 2.00988 13.4442 2.0995 13.0318 2.46923L5.66881 9.20584L5.43752 12.0568C5.65175 12.4969 6.044 12.499 6.29421 12.2802L8.33129 10.3325L11.8201 12.9724C12.6304 13.4571 13.0713 13.1443 13.2457 12.2558L15.534 1.30652C15.7716 0.212878 15.3665 -0.268992 14.3336 0.150026Z"
                  fill="#fff" />
              </svg>
            </a>
          </div>
          <!-- /.social -->
        </div>
      </div>
    </div>
    <!-- /.foot__top -->
    <div class="foot__bottom">
      <div class="container">
        <div class="container__elem container__elem--12 container__elem--df container__elem--aic container__elem--jcsb">
          <div class="footItem">
            <i>Узнай стоимость доставки прямой сейчас</i>
          </div>
          <!-- /.footItem -->
          <div class="footItem">
            <div class="contButton contButton--w200">
              <button class="button button--red" type="button">
                <span>Рассчитать доставку</span>
              </button>
              <!-- /.button button--red -->
            </div>
            <!-- /.contButton contButton--w200 -->
          </div>
          <!-- /.footItem -->
          <div class="footItem">
            <button class="foot__close" type="button">
              <span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M16 1.81133L14.1887 0L8.00001 6.1887L1.81133 1.50985e-05L0 1.81134L6.18869 8.00003L-3.28347e-06 14.1887L1.81132 16L8.00001 9.81135L14.1887 16.0001L16 14.1887L9.81134 8.00003L16 1.81133Z"
                    fill="#F2243A" />
                </svg>
              </span>
            </button>
            <!-- /.foot__close -->
          </div>
          <!-- /.footItem -->
        </div>
      </div>
    </div>
    <!-- /.foot__bottom -->
  </footer>
</template>

<script>
export default {
  name: 'Footers'
}
</script>