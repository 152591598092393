<template>
    <div class="LayoutDefault">
        <Headers />
        <main>
            <slot />
        </main>
        <Footers />
    </div>
</template>
  
<script>
import Headers from '@/components/Headers.vue'
import Footers from '@/components/Footers.vue'

export default {
    name: `LayoutDefault`,
    components: {
        Headers,
        Footers
    }
};
</script>