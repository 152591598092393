<template>
  <header class="header">
    <div class="header__top">
      <div class="container">
        <div class="container__elem container__elem--12  container__elem--df  container__elem--aic">
          <a href="/" class="logo">
            <img src="../assets/img/design/logo.svg" alt="">
          </a>
          <!-- /.logo -->
          <div class="header__pripiska">
            <span>Доставка любых грузов из Китая</span>
          </div>
          <!-- /.header__pripiska -->
          <div class="header__wr">
            <div class="social">
              <a href="#">
                <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18.0027 11.1423H15.9498C15.1731 11.1423 14.9391 10.5132 13.5468 9.12002C12.33 7.94732 11.8161 7.80152 11.5083 7.80152C11.0826 7.80152 10.9665 7.91852 10.9665 8.50442V10.3512C10.9665 10.8507 10.8045 11.1432 9.4995 11.1432C8.23346 11.0582 7.00574 10.6736 5.91747 10.0211C4.8292 9.36857 3.91151 8.46686 3.24 7.39022C1.64555 5.40574 0.536137 3.07659 0 0.588021C0 0.280221 0.117 0.00122113 0.7038 0.00122113H2.7558C3.2832 0.00122113 3.4731 0.236121 3.6801 0.778821C4.6764 3.71102 6.3765 6.26162 7.0668 6.26162C7.3314 6.26162 7.4466 6.14462 7.4466 5.48492V2.46452C7.3593 1.08662 6.6276 0.970521 6.6276 0.471921C6.63686 0.340378 6.69703 0.217625 6.79534 0.129735C6.89365 0.0418451 7.02235 -0.004257 7.1541 0.00122113H10.3797C10.8207 0.00122113 10.9665 0.220821 10.9665 0.748221V4.82522C10.9665 5.26532 11.1555 5.41112 11.2887 5.41112C11.5533 5.41112 11.7567 5.26532 12.2418 4.78112C13.2816 3.51301 14.1311 2.10017 14.7636 0.587121C14.8283 0.405459 14.9507 0.249958 15.1121 0.144337C15.2734 0.0387149 15.4649 -0.0112297 15.6573 0.00212106H17.7102C18.3258 0.00212106 18.4563 0.309921 18.3258 0.749121C17.5791 2.4217 16.6553 4.00937 15.57 5.48492C15.3486 5.82242 15.2604 5.99792 15.57 6.39392C15.7734 6.70172 16.4934 7.30292 16.9767 7.87442C17.6805 8.57642 18.2646 9.38822 18.7065 10.2774C18.8829 10.8498 18.5886 11.1423 18.0027 11.1423Z"
                    fill="#6C90A4" />
                </svg>
              </a>
              <a href="#">
                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.3336 0.150026L0.512706 5.53557C-0.0434705 5.78637 -0.231584 6.28861 0.378278 6.56118L3.92392 7.69979L12.4969 2.34598C12.9649 2.00988 13.4442 2.0995 13.0318 2.46923L5.66881 9.20584L5.43752 12.0568C5.65175 12.4969 6.044 12.499 6.29421 12.2802L8.33129 10.3325L11.8201 12.9724C12.6304 13.4571 13.0713 13.1443 13.2457 12.2558L15.534 1.30652C15.7716 0.212878 15.3665 -0.268992 14.3336 0.150026Z"
                    fill="#6C90A4" />
                </svg>
              </a>
            </div>
            <!-- /.social -->
            <a href="tel:+74996425545" class="tel">+7 (499) 642-55-45</a>
            <!-- /.tel -->
            <div class="contButton contButton--w180">
              <button class="button button--red" type="button">
                <span>Заказать звонок</span>
              </button>
              <!-- /.button -->
            </div>
            <!-- /.contButton contButton--w180 -->
          </div>
          <!-- /.header__wr -->
          <button class="burger" type="button">
            <span>
              <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 1.61538C0 0.723233 0.723232 0 1.61538 0H15.6154C16.5075 0 17.2308 0.723233 17.2308 1.61538C17.2308 2.50754 16.5075 3.23077 15.6154 3.23077H1.61538C0.723233 3.23077 0 2.50754 0 1.61538ZM0 7C0 6.10785 0.723232 5.38462 1.61538 5.38462H15.6154C16.5075 5.38462 17.2308 6.10785 17.2308 7C17.2308 7.89215 16.5075 8.61539 15.6154 8.61539H1.61538C0.723233 8.61539 0 7.89215 0 7ZM1.61538 10.7692C0.723232 10.7692 0 11.4925 0 12.3846C0 13.2768 0.723233 14 1.61538 14H15.6154C16.5075 14 17.2308 13.2768 17.2308 12.3846C17.2308 11.4925 16.5075 10.7692 15.6154 10.7692H1.61538Z"
                  fill="#F2243A" />
              </svg>
            </span>
          </button>
          <!-- /.burger -->
        </div>
      </div>
    </div>
    <!-- /.header__top -->
    <div class="header__bottom">
      <div class="container">
        <div class="container__elem container__elem--12 container__elem--df">
          <a href="/order/show" class="createOrder">Сделать заказ</a>
          <!-- /.createOrder -->
          <nav class="topmenu">
            <ul>
              <li><a href="#">О нас</a></li>
              <li><a href="#">Услуги</a></li>
              <li><a href="#">Условия</a></li>
              <li><a href="#">Отзывы</a></li>
              <li><a href="#">Оплата и доставка</a></li>
              <li><a href="#">Контакты</a></li>
            </ul>
          </nav>
          <!-- /.topmenu -->
        </div>
      </div>
    </div>
    <!-- /.header__bottom -->
  </header>
</template>

<script>
export default {
  name: 'Headers'
}
</script>