<template>
  <swiper :modules="modules"
    :breakpoints="{ 320: { slidesPerView: 1, spaceBetween: 12 }, 768: { slidesPerView: 1, spaceBetween: 12 }, 1201: { slidesPerView: 1, spaceBetween: 12 } }"
    :slides-per-view="1" :space-between="12" :pagination="{ clickable: true }" @swiper="onSwiper"
    @slideChange="onSlideChange">
    <swiper-slide>
      <div class="slide1">
        <img src="../assets/img/design/sl1.jpg" class="slide1__desk" alt="">
        <img src="../assets/img/design/sl1mob.jpg" class="slide1__mob" alt="">
        <div class="container">
          <div class="container__elem container__elem--12">
            <div class="slide1__utp">Доставим ваш груз до двери прямо из Китая</div>
            <!-- /.slide1__utp -->
            <div class="slide1__txt">China Optom – посредник по закупке, доставка КАРГО и белая товаров из Китая. У нас вы
              найдете максимально полный комплекс услуг для бизнеса и частных закупок.</div>
            <!-- /.slide1__txt -->
            <div class="slide1__buttons">
              <div class="contButton contButton--w180">
                <button class="button button--red" type="button"><span>Оформить заказ</span></button>
                <!-- /.button -->
              </div>
              <!-- /.contButton contButton--red -->
              <div class="contButton contButton--w200">
                <button class="button button--white"><span>Рассчитать доставку</span></button>
                <!-- /.button -->
              </div>
              <!-- /.contButton contButton--white -->
            </div>
            <!-- /.slide1__buttons -->
          </div>
        </div>
      </div>
      <!-- /.slide1 -->
    </swiper-slide>
    <swiper-slide>
      <div class="slide1">
        <img src="../assets/img/design/sl2.jpg" class="slide1__desk" alt="">
        <img src="../assets/img/design/sl1mob.jpg" class="slide1__mob" alt="">
        <div class="container">
          <div class="container__elem container__elem--12">
            <div class="slide1__utp">Доставим ваш груз до двери прямо из Китая</div>
            <!-- /.slide1__utp -->
            <div class="slide1__txt">China Optom – посредник по закупке, доставка КАРГО и белая товаров из Китая. У нас вы
              найдете максимально полный комплекс услуг для бизнеса и частных закупок.</div>
            <!-- /.slide1__txt -->
            <div class="slide1__buttons">
              <div class="contButton contButton--w180">
                <button class="button button--red" type="button"><span>Оформить заказ</span></button>
                <!-- /.button -->
              </div>
              <!-- /.contButton contButton--red -->
              <div class="contButton contButton--w200">
                <button class="button button--white"><span>Рассчитать доставку</span></button>
                <!-- /.button -->
              </div>
              <!-- /.contButton contButton--white -->
            </div>
            <!-- /.slide1__buttons -->
          </div>
        </div>
      </div>
      <!-- /.slide1 -->
    </swiper-slide>
    <swiper-slide>
      <div class="slide1">
        <img src="../assets/img/design/sl3.jpg" class="slide1__desk" alt="">
        <img src="../assets/img/design/sl1mob.jpg" class="slide1__mob" alt="">
        <div class="container">
          <div class="container__elem container__elem--12">
            <div class="slide1__utp">Доставим ваш груз до двери прямо из Китая</div>
            <!-- /.slide1__utp -->
            <div class="slide1__txt">China Optom – посредник по закупке, доставка КАРГО и белая товаров из Китая. У нас вы
              найдете максимально полный комплекс услуг для бизнеса и частных закупок.</div>
            <!-- /.slide1__txt -->
            <div class="slide1__buttons">
              <div class="contButton contButton--w180">
                <button class="button button--red" type="button"><span>Оформить заказ</span></button>
                <!-- /.button -->
              </div>
              <!-- /.contButton contButton--red -->
              <div class="contButton contButton--w200">
                <button class="button button--white"><span>Рассчитать доставку</span></button>
                <!-- /.button -->
              </div>
              <!-- /.contButton contButton--white -->
            </div>
            <!-- /.slide1__buttons -->
          </div>
        </div>
      </div>
      <!-- /.slide1 -->
    </swiper-slide>
    <swiper-slide>
      <div class="slide1">
        <img src="../assets/img/design/sl4.jpg" class="slide1__desk" alt="">
        <img src="../assets/img/design/sl1mob.jpg" class="slide1__mob" alt="">
        <div class="container">
          <div class="container__elem container__elem--12">
            <div class="slide1__utp">Доставим ваш груз до двери прямо из Китая</div>
            <!-- /.slide1__utp -->
            <div class="slide1__txt">China Optom – посредник по закупке, доставка КАРГО и белая товаров из Китая. У нас вы
              найдете максимально полный комплекс услуг для бизнеса и частных закупок.</div>
            <!-- /.slide1__txt -->
            <div class="slide1__buttons">
              <div class="contButton contButton--w180">
                <button class="button button--red" type="button"><span>Оформить заказ</span></button>
                <!-- /.button -->
              </div>
              <!-- /.contButton contButton--red -->
              <div class="contButton contButton--w200">
                <button class="button button--white"><span>Рассчитать доставку</span></button>
                <!-- /.button -->
              </div>
              <!-- /.contButton contButton--white -->
            </div>
            <!-- /.slide1__buttons -->
          </div>
        </div>
      </div>
      <!-- /.slide1 -->
    </swiper-slide>
  </swiper>
</template>
  
<script>
// import Swiper core and required modules
// import { Navigation, A11y, EffectFade } from 'swiper';
import { Pagination, A11y } from 'swiper/modules';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// Import Swiper styles
export default {
  name: 'Slider1',

  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination, A11y],
    };
  },
};


</script>