<template>
  <swiper :modules="modules"
    :breakpoints="{ 320: { slidesPerView: 1, spaceBetween: 12 }, 769: { slidesPerView: 2, spaceBetween: 12 }, 1201: { slidesPerView: 3, spaceBetween: 35 } }"
    :slides-per-view="3" :space-between="35" :pagination="{ clickable: true }" @swiper="onSwiper"
    @slideChange="onSlideChange">
    <swiper-slide>
      <div class="slide2">
        <div class="slide2__top">
          <div class="slide2__img">
            <img src="../assets/img/design/r1.jpg" alt="">
          </div>
          <!-- /.slide2__img -->
          <div class="slide2__wr">
            <div class="slide2__title">Ана Кравченко</div>
            <!-- /.slide2__title -->
            <div class="slide2__desk">заказ с <span>Taobao.com</span></div>
            <!-- /.slide2__desk -->
          </div>
          <!-- /.slide2__wr -->
        </div>
        <!-- /.slide2__top -->
        <div class="slide2__content">Круто! Они сделали доставку моей посылки за рекордно короткий срок. Заказал вечером,
          а утром уже получил! Очень доволен их быстрым и качественным обслуживанием. Рекомендую всем!</div>
        <!-- /.slide2__content -->
        <div class="slide2__date">21 авг 2023</div>
        <!-- /.slide2__date -->
      </div>
      <!-- /.slide2 -->
    </swiper-slide>
    <swiper-slide>
      <div class="slide2">
        <div class="slide2__top">
          <div class="slide2__img">
            <img src="../assets/img/design/r2.jpg" alt="">
          </div>
          <!-- /.slide2__img -->
          <div class="slide2__wr">
            <div class="slide2__title">Валерий Никифоров</div>
            <!-- /.slide2__title -->
            <div class="slide2__desk">заказ с <span>Alibaba</span></div>
            <!-- /.slide2__desk -->
          </div>
          <!-- /.slide2__wr -->
        </div>
        <!-- /.slide2__top -->
        <div class="slide2__content">Оперативная и надежная доставка! Моя посылка была отправлена из другой страны, и я беспокоился за ее сохранность. Но "ЭкспрессДоставка" сделала все отлично - упаковали ее тщательно и доставили точно...</div>
        <!-- /.slide2__content -->
        <div class="slide2__date">21 авг 2023</div>
        <!-- /.slide2__date -->
      </div>
      <!-- /.slide2 -->
    </swiper-slide>
    <swiper-slide>
      <div class="slide2">
        <div class="slide2__top">
          <div class="slide2__img">
            <img src="../assets/img/design/r3.jpg" alt="">
          </div>
          <!-- /.slide2__img -->
          <div class="slide2__wr">
            <div class="slide2__title">Стас Р.</div>
            <!-- /.slide2__title -->
            <div class="slide2__desk">заказ с <span>Poizon</span></div>
            <!-- /.slide2__desk -->
          </div>
          <!-- /.slide2__wr -->
        </div>
        <!-- /.slide2__top -->
        <div class="slide2__content">Отличная доставка! Быстро и без проблем получил свою посылку.
          <div class="slide2__images">
            <img src="../assets/img/design/k1.jpg" alt="">
            <img src="../assets/img/design/k2.jpg" alt="">
            <img src="../assets/img/design/k3.jpg" alt="">
          </div>
          <!-- /.slide2__images -->
        </div>
        <!-- /.slide2__content -->
        <div class="slide2__date">21 авг 2023</div>
        <!-- /.slide2__date -->
      </div>
      <!-- /.slide2 -->
    </swiper-slide>
    <swiper-slide>
      <div class="slide2">
        <div class="slide2__top">
          <div class="slide2__img">
            <img src="../assets/img/design/11.jpg" alt="">
          </div>
          <!-- /.slide2__img -->
          <div class="slide2__wr">
            <div class="slide2__title">Стас Р.</div>
            <!-- /.slide2__title -->
            <div class="slide2__desk">заказ с <span>Poizon</span></div>
            <!-- /.slide2__desk -->
          </div>
          <!-- /.slide2__wr -->
        </div>
        <!-- /.slide2__top -->
        <div class="slide2__content">Отличная доставка! Быстро и без проблем получил свою посылку.
          <div class="slide2__images">
            <img src="../assets/img/design/k1.jpg" alt="">
            <img src="../assets/img/design/k2.jpg" alt="">
            <img src="../assets/img/design/k3.jpg" alt="">
          </div>
          <!-- /.slide2__images -->
        </div>
        <!-- /.slide2__content -->
        <div class="slide2__date">21 авг 2023</div>
        <!-- /.slide2__date -->
      </div>
      <!-- /.slide2 -->
    </swiper-slide>
    <swiper-slide>
      <div class="slide2">
        <div class="slide2__top">
          <div class="slide2__img">
            <img src="../assets/img/design/11.jpg" alt="">
          </div>
          <!-- /.slide2__img -->
          <div class="slide2__wr">
            <div class="slide2__title">Стас Р.</div>
            <!-- /.slide2__title -->
            <div class="slide2__desk">заказ с <span>Poizon</span></div>
            <!-- /.slide2__desk -->
          </div>
          <!-- /.slide2__wr -->
        </div>
        <!-- /.slide2__top -->
        <div class="slide2__content">Отличная доставка! Быстро и без проблем получил свою посылку.
          <div class="slide2__images">
            <img src="../assets/img/design/k1.jpg" alt="">
            <img src="../assets/img/design/k2.jpg" alt="">
            <img src="../assets/img/design/k3.jpg" alt="">
          </div>
          <!-- /.slide2__images -->
        </div>
        <!-- /.slide2__content -->
        <div class="slide2__date">21 авг 2023</div>
        <!-- /.slide2__date -->
      </div>
      <!-- /.slide2 -->
    </swiper-slide>
    <swiper-slide>
      <div class="slide2">
        <div class="slide2__top">
          <div class="slide2__img">
            <img src="../assets/img/design/11.jpg" alt="">
          </div>
          <!-- /.slide2__img -->
          <div class="slide2__wr">
            <div class="slide2__title">Стас Р.</div>
            <!-- /.slide2__title -->
            <div class="slide2__desk">заказ с <span>Poizon</span></div>
            <!-- /.slide2__desk -->
          </div>
          <!-- /.slide2__wr -->
        </div>
        <!-- /.slide2__top -->
        <div class="slide2__content">Отличная доставка! Быстро и без проблем получил свою посылку.
          <div class="slide2__images">
            <img src="../assets/img/design/k1.jpg" alt="">
            <img src="../assets/img/design/k2.jpg" alt="">
            <img src="../assets/img/design/k3.jpg" alt="">
          </div>
          <!-- /.slide2__images -->
        </div>
        <!-- /.slide2__content -->
        <div class="slide2__date">21 авг 2023</div>
        <!-- /.slide2__date -->
      </div>
      <!-- /.slide2 -->
    </swiper-slide>
    <swiper-slide>
      <div class="slide2">
        <div class="slide2__top">
          <div class="slide2__img">
            <img src="../assets/img/design/11.jpg" alt="">
          </div>
          <!-- /.slide2__img -->
          <div class="slide2__wr">
            <div class="slide2__title">Стас Р.</div>
            <!-- /.slide2__title -->
            <div class="slide2__desk">заказ с <span>Poizon</span></div>
            <!-- /.slide2__desk -->
          </div>
          <!-- /.slide2__wr -->
        </div>
        <!-- /.slide2__top -->
        <div class="slide2__content">Отличная доставка! Быстро и без проблем получил свою посылку.
          <div class="slide2__images">
            <img src="../assets/img/design/k1.jpg" alt="">
            <img src="../assets/img/design/k2.jpg" alt="">
            <img src="../assets/img/design/k3.jpg" alt="">
          </div>
          <!-- /.slide2__images -->
        </div>
        <!-- /.slide2__content -->
        <div class="slide2__date">21 авг 2023</div>
        <!-- /.slide2__date -->
      </div>
      <!-- /.slide2 -->
    </swiper-slide>
    <swiper-slide>
      <div class="slide2">
        <div class="slide2__top">
          <div class="slide2__img">
            <img src="../assets/img/design/11.jpg" alt="">
          </div>
          <!-- /.slide2__img -->
          <div class="slide2__wr">
            <div class="slide2__title">Стас Р.</div>
            <!-- /.slide2__title -->
            <div class="slide2__desk">заказ с <span>Poizon</span></div>
            <!-- /.slide2__desk -->
          </div>
          <!-- /.slide2__wr -->
        </div>
        <!-- /.slide2__top -->
        <div class="slide2__content">Отличная доставка! Быстро и без проблем получил свою посылку.
          <div class="slide2__images">
            <img src="../assets/img/design/k1.jpg" alt="">
            <img src="../assets/img/design/k2.jpg" alt="">
            <img src="../assets/img/design/k3.jpg" alt="">
          </div>
          <!-- /.slide2__images -->
        </div>
        <!-- /.slide2__content -->
        <div class="slide2__date">21 авг 2023</div>
        <!-- /.slide2__date -->
      </div>
      <!-- /.slide2 -->
    </swiper-slide>
    <swiper-slide>
      <div class="slide2">
        <div class="slide2__top">
          <div class="slide2__img">
            <img src="../assets/img/design/11.jpg" alt="">
          </div>
          <!-- /.slide2__img -->
          <div class="slide2__wr">
            <div class="slide2__title">Стас Р.</div>
            <!-- /.slide2__title -->
            <div class="slide2__desk">заказ с <span>Poizon</span></div>
            <!-- /.slide2__desk -->
          </div>
          <!-- /.slide2__wr -->
        </div>
        <!-- /.slide2__top -->
        <div class="slide2__content">Отличная доставка! Быстро и без проблем получил свою посылку.
          <div class="slide2__images">
            <img src="../assets/img/design/k1.jpg" alt="">
            <img src="../assets/img/design/k2.jpg" alt="">
            <img src="../assets/img/design/k3.jpg" alt="">
          </div>
          <!-- /.slide2__images -->
        </div>
        <!-- /.slide2__content -->
        <div class="slide2__date">21 авг 2023</div>
        <!-- /.slide2__date -->
      </div>
      <!-- /.slide2 -->
    </swiper-slide>
  </swiper>
</template>
  
<script>
// import Swiper core and required modules
// import { Navigation, A11y, EffectFade } from 'swiper';
import { Pagination, A11y } from 'swiper/modules';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// Import Swiper styles
export default {
  name: 'Slider2',

  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination, A11y],
    };
  },
};


</script>